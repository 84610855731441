// Portfolio.js
import React, { useEffect, useState } from 'react';

const Portfolio = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('https://vinnycmd.com/projects.php')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => console.error('Error fetching projects:', error));
  }, []);

  return (
    <div>
      <h2>Portfolio</h2>
      <ul>
        {projects.map(project => (
          <li key={project.projectid}>
            <h3>{project.projectname}</h3>
            <p>{project.owner}</p>
            {project.url && <a href={project.url}>View project</a>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Portfolio;
