import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
const Header = () => {
  return (
    <header className="header">
      <div className="navbar"><img id='vlogo' alt='' className='vlogo' src='vinnycmd.PNG'></img>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/Portfolio">Portfolio</Link></li>
          <li><Link to="/Contact">Contact</Link></li>
        </ul>
      </nav>
      </div>
    </header>
  );
};

export default Header;
    