import React from 'react';

const Home = () => {
  return (
    <header>
      <h1>Welcome to My Website</h1>
    </header>
  );
};

export default Home;
    