import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Navigation</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/About">About Us</a></li>
            <li><a href="/Services">Services</a></li>
            <li><a href="/Portfolio">Portfoliso</a></li>
            <li><a href="/Blog">Blog</a></li>
            <li><a href="/Contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact</h3>
          <p>123 Main Street<br />City, State ZIP<br />Country</p>
          <p>Email: info@example.com<br />Phone: 123-456-7890</p>
          <div className="social-icons">
            {/* Add social media icons here */}
          </div>
        </div>
        <div className="footer-section">
          <h3>Legal</h3>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
            <li><a href="/cookie-policy">Cookie Policy</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; 2024 MyWebsite. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
